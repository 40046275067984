import { GoPlay } from "goplay-ws/src/pkg.pb";
import { Rugbull } from "src/network/generated/pkg.pb";
import eventEmitter from "./eventEmitter";
import { useTranslation } from "react-i18next";

// 判断当前倍数，并获取颜色
export const colors = (value) => {
    if (value >= 0 && value < 2) {
        return '#34b4ff'
    } else if (value >= 2 && value < 10) {
        return '#913ef8';
    } else if (value >= 10) {
        return '#c017b4'
    }
}

// 生成32位哈希值
export const genHash = () => {
    const chars = 'abcdef0123456789';
    let hash = '';
    for (let i = 0; i < 32; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        hash += chars[randomIndex];
    }
    return hash;
};

// 自动生成长度数组
export const generateXAxisPoints = (point?: number) => {
    // 创建一个空数组
    let points = [];
    // 填充数组，从1到20
    for (let i = 1; i <= 400; i++) {
        points.push(point !== undefined ? point : i);
    }
    return points;
}

export const rem = (px: number) => {
    return `${px / 20}rem`
}


export const classifyDataBySecond = (data) => {
    // 创建一个对象来存储归类后的数据
    const classifiedData = {};

    // 遍历数据数组
    data.forEach(item => {
        // 提取startTime和pushTime，并转换为秒级时间戳
        //   if (item.startTime !== undefined && item.pushTime !== undefined) {
        const startSecond = Math.floor(parseInt(item.startTime) / 1000);
        const pushSecond = Math.floor(parseInt(item.pushTime) / 1000);

        // 使用(startTime, pushTime)作为键，如果不存在则初始化为空数组
        classifiedData[`${startSecond},${pushSecond}`] = classifiedData[`${startSecond},${pushSecond}`] || [];

        // 将当前项添加到对应的键下
        classifiedData[`${startSecond},${pushSecond}`].push(item);
        //   }
    });

    // console.log(classifiedData)

    // 处理归类后的数据，生成K线图数据
    const kLineData = Object.entries(classifiedData).map(([timeKey, items]: any) => {
        // console.log('multiplier', timeKey, items)
        const firstItem = items[0];
        const multiValues = items.map(item => parseFloat(item.multiplier));
        const open = parseFloat(firstItem.multiplier); // 开盘价为数组的第一项
        const close = multiValues[multiValues.length - 1]; // 收盘价为数组的最后一项
        const high = Math.max(...multiValues); // 最高价
        const low = Math.min(...multiValues); // 最低价

        // 判断涨跌：涨为绿色，跌为红色
        // const color = open > close ? 'red' : 'green';

        return [Number(timeKey.split(',')[1]), open, high, low, close]
    });

    // console.log('kLineData', kLineData)

    return kLineData;
}

export const OpenLink = (url: string) => {
    if (window.Telegram?.WebApp?.initData && url.startsWith("https://t.me")) {
        window.Telegram.WebApp.openTelegramLink(url);
    } else {
        window.open(url, '_blank');
    }
};
export const OpenLocalLink = (url: string) => {
    if (window.Telegram?.WebApp?.initData) {
        window.Telegram.WebApp.openLink(url);
    } else {
        window.open(url, '_blank');
    }
};

export const GetChainName = (o: Rugbull.Protocols.ChainType) => {
    switch (o) {
        case Rugbull.Protocols.ChainType.ChainType_Bsc:
            return "BSC (BEP-20)";
        case Rugbull.Protocols.ChainType.ChainType_Tron:
            return "TRON (TRC-20)";
        case Rugbull.Protocols.ChainType.ChainType_Ton:
            return "TON (JETTON)";
        case Rugbull.Protocols.ChainType.ChainType_Solana:
            return "SOLANA (SPL)";
    }
};

export const GetCoinName = (o: Rugbull.Protocols.CoinType) => {
    switch (o) {
        case Rugbull.Protocols.CoinType.CoinType_Ton:
            return "TGB";
        case Rugbull.Protocols.CoinType.CoinType_USDT:
            return "USDT"
        case Rugbull.Protocols.CoinType.CoinType_Coin:
            return "xUSDT";
        default:
            return "USDT";
    }
};

export const GetCoinPic = (o: Rugbull.Protocols.CoinType) => {
    switch (o) {
        case Rugbull.Protocols.CoinType.CoinType_Ton:
            return "TGB";
        case Rugbull.Protocols.CoinType.CoinType_USDT:
            return "USDT";
        case Rugbull.Protocols.CoinType.CoinType_Coin:
            return "USDT1";
        default:
            return "USDT";
    };
}

export const HideAddress = (address: string) => {
    if (address.length < 15) {
        return address;
    }
    var a1 = address.substring(0, 4);
    var a2 = address.substring(address.length - 4, address.length)
    return `${a1}*******${a2}`
}

export const GetWithdrawState = (o: Rugbull.Protocols.OrderStatus) => {
    switch (o) {
        case Rugbull.Protocols.OrderStatus.OrderStatus_Waiting:
            return "Waiting";
        case Rugbull.Protocols.OrderStatus.OrderStatus_Purchased:
            return "Success";
        case Rugbull.Protocols.OrderStatus.OrderStatus_Error:
            return "Failed";
        default:
            return "None";
    }
};

export const Show = (status: GoPlay.Core.Protocols.Status) => {
    if (status.Code !== GoPlay.Core.Protocols.StatusCode.Success) {
        eventEmitter.emit("error", status.Message);
        console.log(status);
    }
};



